<template>
  <div class="edit-info-box">
    <van-nav-bar v-if="this.title === '绑定公司'" :title="title" />
    <van-nav-bar v-else :title="title" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <div class="list-box">
        <div class="filed-box">
          <van-field
            v-model="userForm.name"
            name="姓名"
            label="姓名"
            maxlength="20"
            required
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="genderName"
            label="性别"
            required
            clearable
            placeholder="请选择性别"
            readonly
            :rules="[{ required: true, message: '' }]"
            @click="genderPicker = true"
          >
            <template #button>
              <van-icon v-if="genderPicker" name="arrow-up" />
              <van-icon v-else name="arrow-down" />
            </template>
          </van-field>
          <van-popup v-model="genderPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="genderColumns"
              @cancel="genderPicker = false"
              @confirm="genderConfirm"
            >
              <template #option="item">
                <p style="text-align: center">{{ item.label }}</p>
              </template>
            </van-picker>
          </van-popup>
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.position"
            name="当前职位"
            label="当前职位"
            maxlength="50"
            required
            placeholder="请输入当前职位"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.wechat"
            name="微信号"
            label="微信号"
            maxlength="50"
            required
            placeholder="请输入微信号"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
      </div>

      <div class="btn-box">
        <van-button round block type="info" native-type="submit">保存</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: 'baseInfoEdit',
  data() {
    return {
      title: '基本信息修改',
      genderName: '',
      genderPicker: false,
      genderColumns: [
        { label: '男', value: 'MALE' },
        { label: '女', value: 'FEMALE' },
      ],

      userForm: {
        gender: '',
        name: '',
        position: '',
        wechat: '',
      },
    };
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      this.genderName =
        userInfo.userSelfhelpVO.gender === 'MALE' ? '男' : '女';
      this.userForm.gender = userInfo.userSelfhelpVO.gender;
      this.userForm.name = userInfo.userSelfhelpVO.userName;
      this.userForm.position = userInfo.userSelfhelpVO.position;
      this.userForm.wechat = userInfo.userSelfhelpVO.wechat;
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    genderConfirm(item) {
      this.genderName = item.label;
      this.userForm.gender = item.value;
      this.genderPicker = false;
    },
    onSubmit() {
      this.$dialog
        .confirm({
          message: '确定要修改基本信息么？',
        })
        .then(() => {
          this.$http
            .put('/selfhelpOfficialAccounts/users', this.userForm)
            .then(async (res) => {
              if (res.code === 200) {
                this.$toast('修改成功');
                let back = await this.$http.get(
                  '/selfhelpOfficialAccounts/relation'
                );
                if (back.code === 200) {
                  localStorage.setItem(
                    'userInfo',
                    JSON.stringify(back.data)
                  );
                  this.onClickLeft();
                }
              } else {
                this.$toast(res.message || '修改失败');
              }
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-info-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .van-form {
    flex: 1;
    overflow-y: auto;
  }

  .list-box {
    padding: 0 16px;
    margin: 20px 0 40px 0;
    > p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebedf0;
      > .van-cell {
        flex: 1;
      }
      > .van-cell::after {
        border-bottom: none;
      }
      img {
        width: 77px;
        height: 23px;
      }
      ::v-deep .van-field--disabled .van-field__label {
        color: #646566;
      }
      ::v-deep .van-field__value input {
        text-align: right;
      }
      ::v-deep .van-popup__close-icon--top-right {
        top: 2px;
        right: 6px;
      }
      .no-company {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 16px;
        overflow: hidden;
        font-size: 14px;
        line-height: 24px;
        background-color: #fff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #206cfe;
      }
      .no-dept {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 16px;
        overflow: hidden;
        font-size: 14px;
        line-height: 24px;
        background-color: #fff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #206cfe;
        justify-content: center;
      }
    }
    .van-search .van-cell {
      padding: 5px 8px 5px 0;
      ::v-deep .van-field__value input {
        text-align: initial;
      }
    }
    .van-uploader {
      width: 100%;
    }
    ::v-deep .van-uploader__input-wrapper {
      width: 100%;
    }
    .uploader-btn {
      height: 45px;
      line-height: 45px;
      background: #fafafa;
      border-radius: 4px;
      border: 1px dashed #cccccc;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      i {
        color: #f44336;
        font-style: normal;
      }
    }
  }

  .btn-box {
    margin-top: 40px;
    padding: 0 96px;
  }
}
</style>
